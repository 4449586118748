import React from "react";
import { Label, Input, Button, Card, CardBody } from "reactstrap";
import Footer from "../Components/Footer"
import withRouter from "../Components/withRouter"
import axios from "axios";
import { baseUrl } from "../index"
import { citySearch } from "../Config/constants";
import SelectSearch from "react-select-search";
import "../App.css"
import MapComponent from "../Components/SearchMap";
import Favourite from "../Assets/favourite.png"
import Hostel from "../Assets/Hostel.png"
import { amenityCalculator, overallFilter, rateCalculator, roomTypeCalculator } from "../Utils/searchFilters";

class SearchPage extends React.Component {
    constructor() {
        super()
        this.state = {
            fromDate: "",
            toDate: "",
            searchInput: "",
            allHostels: [],
            filteredHostels: [],
            roomTypeFilter: [],
            amenityFilter: [],
            ratingFilter: [],
            priceFilterValue: "",
            amenityFilterValue: "",
            ratingFilterValue: "",
            roomTypeFilterValue: ""
        }
    }

    componentDidMount() {
        const { searchParams } = this.props.router
        this.setState({ searchInput: searchParams.get("city"), fromDate: searchParams.get("from"), toDate: searchParams.get("to") }, () => {
            const { searchInput, fromDate, toDate } = this.state
            axios.get(`${baseUrl}/inventory/search-results?location=${searchInput}&checkInDate=${fromDate}&checkOutDate=${toDate}`).then(response => {
                const roomTypeFilter = roomTypeCalculator(response.data)
                const amenityFilter = amenityCalculator(response.data)
                const ratingFilter = rateCalculator(response.data)
                this.setState({ allHostels: response.data, filteredHostels: response.data, roomTypeFilter, amenityFilter, ratingFilter })
            }).catch(err => {
                console.log(err.message)
            })
        })
    }

    render() {
        const onChange = (e) => {
            const { name, value } = e.target
            const { allHostels, priceFilterValue, roomTypeFilterValue, amenityFilterValue, ratingFilterValue } = this.state
            this.setState({ filteredHostels: allHostels }, () => {
                const filteredHostels = overallFilter(allHostels, {
                    priceFilterValue, roomTypeFilterValue, amenityFilterValue, ratingFilterValue
                })
                this.setState({ [name]: value, filteredHostels })
            })
        }

        const onHostel = (id) => {
            const { fromDate, toDate } = this.state
            window.location.href = `/hostel/?hostel=${id}&from=${fromDate}&to=${toDate}`
        }

        return (
            <div className="bg-tertiary-o">
                <div className="main-container pt-5">
                    <div className="text-white fw-bold h2 mb-3">{this.state.filteredHostels.length} {this.state.filteredHostels.length <= 1 ? "hostel" : "hostels"} found in {this.state.searchInput.charAt(0).toUpperCase() + this.state.searchInput.slice(1).toLowerCase()}, Goa</div>
                    {this.state.allHostels.length === 0 &&
                        <div>
                            No hostel is available
                        </div>}
                    <div className="d-flex gap-3 mb-5 flex-wrap">
                        <Input onChange={onChange} value={this.state.priceFilterValue} name="priceFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                            <option>Price</option>
                            <option value="LH">Price - low to high</option>
                            <option value="HL">Price - high to low</option>
                        </Input>
                        <Input onChange={onChange} value={this.state.amenityFilterValue} name="amenityFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                            <option>Amenities</option>
                            {this.state.amenityFilter.map(eachAmenity => {
                                return (
                                    <option value={eachAmenity} key={eachAmenity}>{eachAmenity}</option>
                                )
                            })}
                        </Input>
                        <Input onChange={onChange} value={this.state.ratingFilterValue} name="ratingFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                            <option>Rating</option>
                            {this.state.ratingFilter.map(eachRating => {
                                return (
                                    <option value={eachRating} key={eachRating}>{eachRating}</option>
                                )
                            })}
                        </Input>
                        <Input onChange={onChange} value={this.state.roomTypeFilterValue} name="roomTypeFilterValue" style={{ borderRadius: "2rem", width: "max-content" }} type="select">
                            <option>Room Type</option>
                            {this.state.roomTypeFilter.map(eachRoomType => {
                                return (
                                    <option value={eachRoomType} key={eachRoomType}>{eachRoomType}</option>
                                )
                            })}
                        </Input>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-2 g-3 mb-3">
                        <div className="col">
                            {this.state.filteredHostels.map(eachCard => {
                                return (
                                    <div key={eachCard} className="col">
                                        <Card onClick={() => onHostel(eachCard.id)} style={{ cursor: "pointer" }} className="feature-card mb-3">
                                            <CardBody>
                                                <div className="d-flex gap-3 flex-wrap">
                                                    <div>
                                                        <img src={eachCard.thumbnail} style={{ borderRadius: "1rem", marginBottom: "4rem", width: "300px" }} alt="HostelCo" />
                                                        <div style={{ marginTop: "-6rem", width: "max-content", zIndex: "4" }} className="bg-tertiary-o px-2 py-1 text-white">Rs. {eachCard.price}</div>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-between">
                                                        <div>
                                                            <div className="text-white fw-bold">{eachCard.hostel_name}</div>
                                                            <div className="text-text mb-2">{eachCard.location}</div>
                                                            <div className="d-flex gap-2 flex-wrap mb-5">
                                                                {eachCard.amenities.map(eachAmenity => {
                                                                    return (
                                                                        <div className="px-2 py-1 rounded text-white" style={{ backgroundColor: "#402929", fontSize: "0.75rem" }}>{eachAmenity}</div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="text-white px-3 py-1" style={{ backgroundColor: "#2D7912", borderRadius: "2rem", width: "max-content" }}>
                                                            <i className="bi bi-star-fill"></i> {eachCard.rating}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default withRouter(SearchPage)