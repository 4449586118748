import React from "react";
import Logo from "../Assets/Logo.svg"

class Toolbar extends React.Component {
    render() {
        return (
            <div className="py-3 bg-dark-o">
                <div className="main-container d-flex gap-3 align-items-center">
                    <a href="/">
                        <img src={Logo} alt="logo" style={{ height: "40px" }} />
                    </a>
                    {/* <div className="ms-auto">
                        <Button className="d-none d-lg-block host-button">
                            Become A Host
                        </Button>
                    </div>
                    <Avatar></Avatar> */}
                </div>
            </div>
        )
    }
}

export default Toolbar