import React from "react";
import { Button, Card, CardBody, Input, Label, Modal, ModalBody, ModalHeader, InputGroup } from "reactstrap";
import Footer from "../Components/Footer";
import withRouter from "../Components/withRouter";
import axios from "axios";
import { baseUrl } from "../index"
import Moment from "react-moment";
import randomColor from "randomcolor";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HostelRoom from "../Assets/HostelRoom.png"
import Slider from "react-slick";
import { connect } from "react-redux";
import { bookedHostel } from "../Redux/booking";

class HostelPage extends React.Component {
    constructor() {
        super();
        this.state = {
            checkInDate: "",
            checkOutDate: "",
            hostelId: 0,
            days: 0,
            hostel: [],
            totalAmount: 0,
            inventory: [],
            allImages: [],
            isModal: false,
            bookingData: {}
        }
    }

    async componentDidMount() {
        const { searchParams } = this.props.router
        this.setState({ hostelId: searchParams.get("hostel"), checkInDate: searchParams.get("from"), checkOutDate: searchParams.get("to") }, () => {
            const d1 = new Date(this.state.checkInDate);
            const d2 = new Date(this.state.checkOutDate);
            const timeDifference = Math.abs(d2 - d1);
            const differenceInDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            this.setState({ days: differenceInDays })
        })
        axios.get(`${baseUrl}/hostel/hostel/${searchParams.get("hostel")}`).then(response => {
            this.setState({ hostel: response.data })
        })
        axios.get(`${baseUrl}/inventory/hostel-inventory/${searchParams.get("hostel")}`).then(response => {
            const fromDate = new Date(searchParams.get("from"))
            const toDate = new Date(searchParams.get("to"))
            const temp = response.data.filter(item => {
                const itemFromDate = new Date(item.fromDate);
                const itemEndDate = new Date(item.endDate);
                return itemFromDate <= toDate &&
                    itemEndDate >= fromDate &&
                    item.availableRooms > item.bookedRooms;
            });
            this.setState({ inventory: temp }, () => console.log(this.state.inventory))
        })
        axios.get(`${baseUrl}/inventory/hostel-images/cloudinary?folder=${searchParams.get("hostel")}`).then(response => {
            this.setState({ allImages: response.data })
        })


    }

    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onReserve = () => {
            const { checkInDate, checkOutDate, totalAmount, bookingData, hostelId } = this.state
            localStorage.setItem("bookingData", JSON.stringify({
                totalAmount, bookingData, checkInDate, checkOutDate, hostelId
            }))
            this.props.bookedHostel({
                totalAmount, bookingData
            })
            window.location.href = `/booking`
        }

        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "0px",
            arrows: true,
            indicator: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };

        const roomAdded = (inventory) => {
            const { roomType, cost } = inventory
            const { bookingData, days } = this.state
            let roomData = bookingData
            if (!roomData[roomType]) {
                roomData[roomType] = { totalRooms: 1, cost }
            }
            else {
                roomData[roomType].totalRooms += 1
            }
            const totalAmount = Object.values(roomData).reduce((sum, room) => {
                return sum + (room.totalRooms * room.cost * days * 1.1);
            }, 0);
            this.setState({ bookingData: roomData, totalAmount: parseInt(totalAmount) })
        }

        const roomRemoved = (inventory) => {
            const { roomType } = inventory
            const { bookingData, days } = this.state
            let roomData = bookingData
            if (!roomData[roomType]) {
                return
            }
            else {
                roomData[roomType].totalRooms -= 1
                if (roomData[roomType].totalRooms === 0)
                    delete roomData[roomType]
            }
            const totalAmount = Object.values(roomData).reduce((sum, room) => {
                return sum + (room.totalRooms * room.cost * days * 1.1);
            }, 0);
            this.setState({ bookingData: roomData, totalAmount: parseInt(totalAmount) })
        }

        return (
            <div className="bg-tertiary-o">
                <div className="main-container pt-3">
                    <div className="d-flex gap-3 align-items-center mb-3">
                        <div className="text-white h2 fw-bold mb-0">{this.state.hostel[0]?.hostel_name}</div>
                        <div className="text-white px-3 py-1" style={{ backgroundColor: "#2D7912", borderRadius: "2rem", width: "max-content" }}>
                            <i className="bi bi-star-fill"></i> {this.state.hostel[0]?.rating}
                        </div>
                    </div>
                    <div className="d-flex gap-3">
                        {this.state.hostel[0]?.amenities.map(eachAmenity => {
                            return (
                                <div className="text-white px-3 py-1" style={{ backgroundColor: randomColor(), borderRadius: "0.5rem" }}>
                                    {eachAmenity}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="mt-3">
                    <Slider {...settings}>
                        {this.state.allImages.map(eachImage => {
                            return (
                                <div style={{ cursor: "pointer" }} onClick={() => this.setState({ isModal: true })}>
                                    <img src={eachImage} alt="hostelco" style={{ width: "100%" }} />
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className="main-container mt-5">
                    <div className="d-flex gap-3 justify-content-between mb-5 flex-wrap">
                        <div>
                            <div className="h4 text-white mb-3 fw-bold">About</div>
                            <div className="text-white">
                                {this.state.hostel[0]?.description}
                            </div>
                        </div>
                        <div className="d-flex gap-3 mb-3 justify-content-between flex-lg-row flex-column">
                            <div>
                                <Card style={{ borderRadius: "1.3rem" }}>
                                    <CardBody>
                                        <div className="fw-bold h4 text-dark mb-0">
                                            Booking summary
                                        </div>
                                        <div className="fw-bold mb-3" style={{ color: "#ED452F" }}>
                                            {this.state.days} {this.state.days === 1 ? "Night" : "Nights"} starting from <span className="me-1"></span>
                                            <Moment format="DD MMM YYYY">
                                                {this.state.checkInDate}
                                            </Moment>
                                        </div>
                                        <div className="row row-cols-lg-2 row-cols-1 g-3 mb-3">
                                            <div className="col">
                                                <Label className="fw-bold">Check In</Label>
                                                <Input disabled placeholder="Check In Date" type="date" name="checkInDate" onChange={onChange} value={this.state.checkInDate} />
                                            </div>
                                            <div className="col">
                                                <Label className="fw-bold">Check Out</Label>
                                                <Input disabled placeholder="Check Out Date" type="date" name="checkOutDate" onChange={onChange} value={this.state.checkOutDate} />
                                            </div>
                                        </div>
                                        {Object.keys(this.state.bookingData).length !== 0 &&
                                            <div>
                                                {Object.keys(this.state.bookingData).map((roomType, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className="fw-bold text-dark-o mb-2">{roomType}</div>
                                                            <div className="d-flex justify-content-between mb-1">
                                                                <div>
                                                                    <i className="bi bi-currency-rupee"></i>
                                                                    {this.state.bookingData[roomType].cost} <i className="bi bi-x"></i>
                                                                    {this.state.bookingData[roomType].totalRooms} <i className="bi bi-x"></i>
                                                                    {this.state.days} {this.state.days === 1 ? "day" : "days"}</div>
                                                                <div className="fw-bold">
                                                                    <i className="bi bi-currency-rupee"></i>
                                                                    {this.state.bookingData[roomType].cost * this.state.bookingData[roomType].totalRooms * this.state.days}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between mb-1">
                                                                <div>Tax.</div>
                                                                <div className="fw-bold">
                                                                    <i className="bi bi-currency-rupee"></i>
                                                                    {this.state.bookingData[roomType].cost * this.state.bookingData[roomType].totalRooms * this.state.days * 0.1}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between mb-1">
                                                                <div className="fw-bold">Total</div>
                                                                <div className="fw-bold">
                                                                    <i className="bi bi-currency-rupee"></i>
                                                                    {parseInt(this.state.bookingData[roomType].cost * this.state.bookingData[roomType].totalRooms * this.state.days * 1.1)}
                                                                </div>
                                                            </div>
                                                            <hr className="hr mb-3" />
                                                        </div>
                                                    )
                                                })}
                                                <div className="d-flex justify-content-between mb-3">
                                                    <div className="fw-bold">Total</div>
                                                    <div className="fw-bold"><i className="bi bi-currency-rupee"></i>
                                                        {this.state.totalAmount}
                                                    </div>
                                                </div>
                                                <Button style={{ borderRadius: "0.5rem" }} onClick={onReserve} className="w-100 mb-3 bg-dark-o" >
                                                    Book Now
                                                </Button>
                                            </div>
                                        }
                                        {Object.keys(this.state.bookingData).length === 0 &&
                                            <a style={{ borderRadius: "0.5rem" }} href="#select-rooms" className="btn w-100 mb-3 bg-dark-o text-white" >
                                                Select room
                                            </a>
                                        }

                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-secondary-o">
                    <div className="main-container">
                        <div className="text-white h4 mb-3 py-5 fw-bold">What this place offers</div>
                        <div className="text-white mb-2 h5 fw-bold">Amenities</div>
                        <div className="row row-cols-2 row-cols-lg-4 g-3 text-white mb-5">
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Swimming Pool
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Pets allowed
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Power Backup
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Washing Machine
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Kitchen
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Bathroom
                            </div>
                        </div>
                        <div className="text-white mb-2 h5 fw-bold">Experiences</div>
                        <div className="row row-cols-2 row-cols-lg-4 g-3 text-white mb-5">
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Swimming Pool
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Pets allowed
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Power Backup
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Washing Machine
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Kitchen
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Bathroom
                            </div>
                        </div>
                        <div className="text-white mb-2 h5 fw-bold">Basics</div>
                        <div className="row row-cols-2 row-cols-lg-4 g-3 text-white mb-5 pb-5">
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Swimming Pool
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Pets allowed
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Power Backup
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Washing Machine
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Kitchen
                            </div>
                            <div className="col">
                                <i style={{ color: "#0E8345" }} className="bi bi-check-lg me-3"></i> Bathroom
                            </div>
                        </div>
                    </div>
                </div>
                <div id="select-rooms" className="main-container">
                    <div className="h2 text-white fw-bold mb-3">Select Rooms</div>
                    {this.state.inventory.map(eachInventory => {
                        return (
                            <div className="mb-3">
                                <Card style={{ maxWidth: "600px" }} className="feature-card">
                                    <CardBody>
                                        <div className="d-flex gap-3">
                                            <img src={HostelRoom} alt="hostelCo" />
                                            <div className="w-100">
                                                <div className="text-white h5 mb-1">{eachInventory.roomType}</div>
                                                <div className="d-flex justify-space-between gap-3 mb-3 align-items-center w-100">
                                                    <div>
                                                        <div className="text-white h3 mb-1 fw-bold"><i className="bi bi-currency-rupee"></i>{eachInventory.cost}</div>
                                                        <div className="text-white mb-2">
                                                            <i className="bi bi-person text-white"></i> 1
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto">
                                                        {!this.state.bookingData[eachInventory.roomType] &&
                                                            <Button onClick={() => roomAdded(eachInventory)} className="bg-white text-secondary-o px-4 fw-bold add-button" >
                                                                Add
                                                            </Button>
                                                        }
                                                        {this.state.bookingData[eachInventory.roomType] &&
                                                            <InputGroup className="border-0">
                                                                <Button style={{ borderRadius: "0.5rem" }} onClick={() => roomRemoved(eachInventory)} className="add-button bg-white text-dark-o border-0 py-2" size="sm">
                                                                    <i className="bi bi-dash"></i>
                                                                </Button>
                                                                <Input className="text-center fw-bold text-dark-o bg-transparent text-white border-0" style={{ width: "40px" }} placeholder="0"
                                                                    value={this.state.bookingData[eachInventory.roomType].totalRooms} />
                                                                <Button style={{ borderRadius: "0.5rem" }} onClick={() => roomAdded(eachInventory)} className="add-button bg-white fw-bold text-dark-o border-0 py-2" size="sm">
                                                                    <i className="bi bi-plus-lg"></i>
                                                                </Button>
                                                            </InputGroup>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="text-white px-3 py-1" style={{ backgroundColor: "#2D7912", borderRadius: "2rem", width: "max-content" }}>
                                                    Only {eachInventory.availableRooms - eachInventory.bookedRooms} bed available
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                        )
                    })}
                </div>
                <div className="main-container">
                    <div className="text-white h2 mb-3 fw-bold">
                        Location
                    </div>
                    <iframe src={this.state.hostel[0]?.location_url} title="maps"
                        width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <Footer />
                <Modal scrollable size="xl" isOpen={this.state.isModal} toggle={() => this.setState({ isModal: false })}>
                    <ModalHeader toggle={() => this.setState({ isModal: false })}>All Hostel Images</ModalHeader>
                    <ModalBody>
                        <div className="row row-cols-1 row-cols-lg-3">
                            {this.state.allImages.map(eachImage => {
                                return (
                                    <div key={eachImage} className="row">
                                        <img className="m-2" src={eachImage} alt="hostelco" />
                                    </div>
                                )
                            })}

                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    bookingData: state.booking.bookingData
})

const mapDispatchToProps = {
    bookedHostel
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HostelPage))