import React from "react";
import Footer from "../Components/Footer";
import { Card, CardBody, CardHeader, Label, Input, InputGroup, InputGroupText, Button, FormGroup, Alert } from "reactstrap";
import { couponCodes } from "../Config/constants";
import axios from "axios";
import { baseUrl } from "..";
import withRouter from "../Components/withRouter";
import firebase from "firebase";
import { auth } from "../config";
import { connect } from "react-redux";
import { bookedHostel } from "../Redux/booking";

class BookingPage extends React.Component {
    constructor() {
        super()
        this.state = {
            firstName: "",
            middleName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            isOtpSent: false,
            otp: "",
            terms: false,
            couponCode: "",
            couponValue: 0,
            message: "",
            messageColor: "",
            days: 0,
            price: 0,
            hostel: {},
            isCouponApplied: false,
            isOTPValid: false,
            result: {},
            bookingData: {}
        }
    }

    componentDidMount() {
        axios.get(`${baseUrl}/hostel/hostel/${JSON.parse(localStorage.getItem("bookingData")).hostelId}`).then(response => {
            this.setState({ hostel: response.data, bookingData: JSON.parse(localStorage.getItem("bookingData")) }, () => {
                const d1 = new Date(this.state.bookingData.checkInDate);
                const d2 = new Date(this.state.bookingData.checkOutDate);
                const timeDifference = Math.abs(d2 - d1);
                const differenceInDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                this.setState({ days: differenceInDays, price: this.state.bookingData.totalAmount, hostelId: this.state.bookingData.hostelId })
            })
        })
    }
    render() {
        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onSubmit = async () => {
            const res = await loadScript(
                "https://checkout.razorpay.com/v1/checkout.js"
            );
            if (!res) return;
            const { firstName, middleName, lastName, phoneNumber, email, bookingData, isOTPValid } = this.state
            const options = {
                key: "rzp_test_u4KVWoUnT6RTGu",
                currency: "INR",
                amount: this.state.price * 100,
                name: "Hostel Co",
                description: `Payment for Hostel Booking`,
                handler: function async(response) {
                    console.log(response)
                    if (!isOTPValid) {
                        return
                    }
                    const data = {
                        firstName, middleName, lastName, phoneNumber,
                        email, bookingData, razorpayId: response.payment_id
                    }
                    const token = localStorage.getItem("token")
                    const headers = {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }
                    axios.post(`${baseUrl}/reservations/booking`, data, headers).then(data => {
                        window.location.href = "/"
                    }).catch(err => {
                        console.log(err.message)
                    })
                },
                prefill: {
                    name: `${firstName} ${middleName} ${lastName}`,
                    contact: phoneNumber,
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }

        const loadScript = (src) => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = src;
                script.onload = () => {
                    resolve(true);
                };
                script.onerror = () => {
                    resolve(false);
                };
                document.body.appendChild(script);
            });
        }

        const sendOTP = () => {
            window.verifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                "size": "invisible",
                "callback": (response) => {
                }
            });
            const appVerifier = window.verifier
            auth.signInWithPhoneNumber(("+91" + this.state.phoneNumber), appVerifier)
                .then((confirmationResult) => {
                    this.setState({ result: confirmationResult, message: "OTP has been sent", messageColor: "success", isOtpSent: true })
                }).catch((error) => {
                    console.log(error);
                    this.setState({ message: error.message, messageColor: "danger" })
                });
        }

        const checkCoupon = () => {
            const { couponCode, bookingData } = this.state
            couponCodes.map(eachCoupon => {
                if (eachCoupon.name === couponCode) {
                    this.setState({ price: bookingData.totalAmount - eachCoupon.value, couponValue: eachCoupon.value, message: "Coupon applied", isCouponApplied: true, messageColor: "success" })
                    setTimeout(() => {
                        this.setState({ message: "" })
                    }, 3000)
                    return 0
                }
                this.setState({ message: "Coupon not valid", messageColor: "danger", isCouponApplied: false, price: bookingData.totalAmount, couponValue: 0 })
                setTimeout(() => {
                    this.setState({ message: "" })
                }, 3000)
                return 0
            })
        }

        const confirmOTP = () => {
            const { firstName, middleName, lastName, email, phoneNumber } = this.state
            this.state.result.confirm(this.state.otp).then(result => {
                const data = {
                    name: `${firstName} ${middleName} ${lastName}`,
                    email: email,
                    password: "Test@123",
                    phoneNumber,
                    role: "user"
                }
                axios.post(`${baseUrl}/auth/register`, data).then(response => {
                    localStorage.setItem("token", response.data.token)
                    this.setState({ message: "Authenticated. Please proceed to pay", messageColor: "success", isOTPValid: true, isOtpSent: false })
                    setTimeout(() => {
                        this.setState({ submitMessage: "" })
                    }, 3000)
                }).catch(err => {
                    console.log(err.message)
                })
            }).catch(err => {
                this.setState({ submitMessage: "Please enter correct OTP", messageColor: "danger" })
                setTimeout(() => {
                    this.setState({ submitMessage: "" })
                }, 3000)
            })
        }

        const disabledPay = !(this.state.firstName && this.state.email && this.state.phoneNumber && this.state.terms && this.state.isOTPValid)

        return (
            <div className="bg-tertiary-o">
                <div className="main-container">
                    <div className="py-5 h2 fw-bold text-white">Secure your Booking</div>
                    <div className="row row-cols-lg-2 row-cols-1 g-3 mb-5">
                        <div className="col-12 col-lg-8">
                            <Card className="mb-5 bg-secondary-o feature-card">
                                <CardHeader className="bg-tertiary-o d-flex gap-3 text-white align-items-center">
                                    <i className="bi bi-person-circle fs-5"></i> <div className="h5 mb-0">Customer Name</div>
                                </CardHeader>
                                <CardBody>
                                    <div className="row row-cols-1 row-cols-lg-3 g-3">
                                        <div className="col">
                                            <Label className="text-light-gray">First Name</Label>
                                            <Input placeholder="First Name" onChange={onChange} value={this.state.firstName} name="firstName" className="bg-dark-o text-primary-o" />
                                        </div>
                                        <div className="col">
                                            <Label className="text-light-gray">Middle Name</Label>
                                            <Input placeholder="Middle Name" onChange={onChange} value={this.state.middleName} name="middleName" className="bg-dark-o text-primary-o" />
                                        </div>
                                        <div className="col">
                                            <Label className="text-light-gray">Last Name</Label>
                                            <Input placeholder="Last Name" onChange={onChange} value={this.state.lastName} name="lastName" className="bg-dark-o text-primary-o" />
                                        </div>
                                        <div className="col">
                                            <Label className="text-light-gray">Email</Label>
                                            <Input placeholder="Email" onChange={onChange} value={this.state.email} name="email" className="bg-dark-o text-primary-o" />
                                        </div>
                                        <div className="col">
                                            <Label className="text-light-gray">Mobile Number</Label>
                                            <InputGroup className="border rounded">
                                                <InputGroupText className="bg-white text-dark border-0">
                                                    +91
                                                </InputGroupText>
                                                <Input className="border-o bg-dark-o text-primary-o" placeholder="Phone Number" onChange={onChange} value={this.state.phoneNumber} name="phoneNumber" />
                                            </InputGroup>
                                        </div>
                                        {!this.state.isOtpSent &&
                                            <div className="col d-flex align-items-end">
                                                <Button onClick={sendOTP} disabled={!this.state.phoneNumber} className="bg-primary-o">
                                                    Send OTP
                                                </Button>
                                            </div>}
                                        {this.state.isOtpSent &&
                                            <div className="col">
                                                <Label className="text-light-gray">Enter OTP</Label>
                                                <Input placeholder="OTP" onChange={onChange} value={this.state.otp} name="otp" />
                                            </div>}
                                        {this.state.isOtpSent &&
                                            <div className="col d-flex align-items-end">
                                                <Button onClick={confirmOTP} disabled={!this.state.phoneNumber} className="bg-primary-o">
                                                    Confirm
                                                </Button>
                                            </div>}
                                        {this.state.message &&
                                            <Alert color={this.state.messageColor}>
                                                {this.state.message}
                                            </Alert>}
                                    </div>
                                </CardBody>
                            </Card>
                            <div>
                                <div className="h3 fw-bold text-white mb-3">Terms and Conditions</div>
                                <ol className="mb-5 text-white">
                                    <li>Booking once done is non transferable and non refundable.</li>
                                    <li>Hostel own the right to cancel or modify the booking at their discretion.</li>
                                    <li>Booking once done is non transferable and non refundable.</li>
                                    <li>Hostel own the right to cancel or modify the booking at their discretion.</li>
                                    <li>Booking once done is non transferable and non refundable.</li>
                                    <li>Hostel own the right to cancel or modify the booking at their discretion.</li>
                                    <li>Booking once done is non transferable and non refundable.</li>
                                    <li>Hostel own the right to cancel or modify the booking at their discretion.</li>
                                </ol>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <Card className="feature-card border-o mb-3">
                                <CardBody>
                                    <div className="mb-3">
                                        <img src={this.state.hostel[0]?.thumbnail} style={{ borderRadius: "1rem" }} alt="hostelco" className="w-100" />
                                    </div>
                                    <div className="h4 text-white mb-2">
                                        {this.state.hostel[0]?.hostel_name}
                                    </div>
                                    <div className="text-white mb-3">
                                        {this.state.hostel[0]?.description}
                                    </div>
                                    <div className="d-flex gap-4 mb-3 text-white">
                                        <div className="fw-bold">
                                            <i className="bi bi-star-fill me-1"></i> {this.state.hostel[0]?.rating}
                                        </div>
                                        <div>
                                            {this.state.hostel[0]?.total_reviews} reviews
                                        </div>
                                    </div>
                                    <div className="d-flex gap-3 justify-content-between text-white">
                                        <div className="fw-bold">
                                            <i className="bi bi-geo-alt me-1"></i> {this.state.hostel[0]?.location.charAt(0).toUpperCase() + this.state.hostel[0]?.location.slice(1).toLowerCase()}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="mb-5 feature-card">
                                <CardHeader className="bg-tertiary-o d-flex gap-3 text-white align-items-center">
                                    <div className="h5 fw-bold mb-0">Price Details</div>
                                </CardHeader>
                                <CardBody>
                                    {Object.keys(this.state.bookingData).length !== 0 &&
                                        <div>
                                            {Object.keys(this.state.bookingData["bookingData"]).map((roomType, index) => {
                                                return (
                                                    <div key={index} className="text-white">
                                                        <div className="fw-bold text-white mb-2">{roomType}</div>
                                                        <div className="d-flex justify-content-between mb-1">
                                                            <div>
                                                                <i className="bi bi-currency-rupee"></i>
                                                                {this.state.bookingData["bookingData"][roomType].cost} <i className="bi bi-x"></i>
                                                                {this.state.bookingData["bookingData"][roomType].totalRooms} <i className="bi bi-x"></i>
                                                                {this.state.days} {this.state.days === 1 ? "day" : "days"}</div>
                                                            <div className="fw-bold">
                                                                <i className="bi bi-currency-rupee"></i>
                                                                {this.state.bookingData["bookingData"][roomType].cost * this.state.bookingData["bookingData"][roomType].totalRooms * this.state.days}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-1">
                                                            <div>Tax.</div>
                                                            <div className="fw-bold">
                                                                <i className="bi bi-currency-rupee"></i>
                                                                {this.state.bookingData["bookingData"][roomType].cost * this.state.bookingData["bookingData"][roomType].totalRooms * this.state.days * 0.1}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-1">
                                                            <div className="fw-bold">Total</div>
                                                            <div className="fw-bold">
                                                                <i className="bi bi-currency-rupee"></i>
                                                                {parseInt(this.state.bookingData["bookingData"][roomType].cost * this.state.bookingData["bookingData"][roomType].totalRooms * this.state.days * 1.1)}
                                                            </div>
                                                        </div>
                                                        <hr className="hr mb-3" />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    {this.state.isCouponApplied &&
                                        <div className="d-flex justify-content-between gap-3 mb-2 text-success">
                                            <div>Coupon Applied</div>
                                            <div className="fw-bold">- Rs. {this.state.couponValue}</div>
                                        </div>}
                                    <hr className="hr" />
                                    <div className="d-flex justify-content-between gap-3 text-white">
                                        <div className="h5 mb-0">Total</div>
                                        <div className="fw-bold"><i className="bi bi-currency-rupee"></i> {this.state.price}</div>
                                    </div>
                                    <div className="text-light-gray mb-3">
                                        Use a coupon, credit or promotional code
                                    </div>
                                    <Label className="fw-bold text-white">Coupon code</Label>
                                    <div className="d-flex gap-2 align-items-center flex-wrap mb-2">
                                        <Input className="w-50 bg-dark-o text-primary-o" placeholder="Enter coupon code" value={this.state.couponCode} onChange={onChange} name="couponCode" />
                                        <Button onClick={checkCoupon} className="bg-dark-o">
                                            Apply Coupon
                                        </Button>
                                    </div>
                                    {this.state.message &&
                                        <Alert color={this.state.messageColor}>
                                            {this.state.message}
                                        </Alert>}
                                    <div className="d-flex gap-3 align-items-center mt-5 mb-2">
                                        <FormGroup check>
                                            <Input type="checkbox" checked={this.state.terms} onChange={() => this.setState({ terms: !this.state.terms })} />
                                            <Label className="fw-bold text-white" check>
                                                I have read and understood all the terms and conditions.
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Button disabled={disabledPay} onClick={onSubmit} className="w-50" color="success" size="lg">
                                            Pay Now
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
                <div id="recaptcha-container"></div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    bookingData: state.booking.bookingData
})

const mapDispatchToProps = {
    bookedHostel
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BookingPage))