export const couponCodes = [
    { name: "HO50", value: 50 }
]

export const customerFeedback = [
    {
        "name": "Ankit Anand",
        "location": "Mumbai",
        "decription": "User-friendly platform with helpful reviews. Found the perfect hostel for my trip.",
        "url": "/ankit.jpeg"
    },
    {
        "name": "Ravichandar Sharma",
        "location": "Kota, Rajasthan",
        "decription": "Hassle-free booking, excellent customer service, and amazing hostel choices.",
        "url": "/ravi.png"
    },
    {
        "name": "Nishita Patil",
        "location": "Delhi",
        "decription": "Best platform for budget travelers! Easy to use, reliable, and affordable",
        "url": "/nishita.png"
    }
]